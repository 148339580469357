import * as fb from "../../firebase/index.js";
import axios from "axios";
export default {
  state: {
    allRegisteredUsers: [],
    allInvitedUsers: [],
    invitedUserProfile: {},
  },
  getters: {
    allRegisteredUsers: (state) => state.allRegisteredUsers,
    allInvitedUsers: (state) => state.allInvitedUsers,
    invitedUserProfile: (state) => state.invitedUserProfile,
  },
  mutations: {
    setAllRegisteredUsers(state, user) {
      state.allRegisteredUsers.push(user);
    },
    clearRegisteredUsers(state) {
      state.allRegisteredUsers = [];
    },
    setAllInvitedUsers(state, users) {
      state.allInvitedUsers = users;
    },
    setInvitedUserProfile(state, data) {
      state.invitedUserProfile = data;
    },
  },
  actions: {
    // This action will be called when logged In user role will be Super Admin or Admin
    getAllRegisteredUsers({ commit, getters }) {
      commit("clearRegisteredUsers");
      return new Promise((resolve, reject) => {
        let userList;
        if (getters.isSuperAdmin) {
          userList = fb.registeredUsersCollection;
        } else {
          userList = fb.registeredUsersCollection.where("userRole", "in", [
            "Admin",
            "General",
          ]);
        }

        userList.onSnapshot(
          (users) => {
            users.docs.forEach((user) => {
              let userListener = user.id.toString();
              // Each user will have its individual snapshot
              //if (user.id != getters.userId) {
              userListener = fb.registeredUsersCollection
                .doc(user.id)
                .onSnapshot((snapshot) => {
                  // Below is added to remove existing entry
                  let index = getters.allRegisteredUsers.findIndex(
                    (user) => user.id === snapshot.id
                  );
                  if (index !== -1) {
                    getters.allRegisteredUsers.splice(index, 1);
                  }
                  // The below check is required for - If the user is deleted from DB, onSnapshot returns the deleted user with Exists flag as false
                  // Data will only be stored when Exists flag is true
                  if (snapshot.exists) {
                    commit("setAllRegisteredUsers", snapshot);
                  }
                });
              //}
            });
            resolve("loaded");
          },
          (error) => {
            reject(error.message);
          }
        );
      });
    },

    // The action will fetch all the invited users from the database
    getAllInvitedUsers({ commit, getters }) {
      return new Promise((resolve, reject) => {
        let userList;
        if (getters.isSuperAdmin) {
          userList = fb.invitedUsersCollection;
        } else {
          userList = fb.invitedUsersCollection.where("userRole", "in", [
            "Admin",
            "General",
          ]);
        }
        userList.onSnapshot(
          (snapshot) => {
            if (snapshot.empty) console.log("Empty user collection");
            commit("setAllInvitedUsers", snapshot.docs);
            resolve("Fetched all Invited Users in collection");
          },
          (error) => {
            reject(error.message);
          }
        );
      });
    },

    // ------- Update Registered OR Invited Users
    updateUserEmail({ state }, updatedUserData) {
      const axios = require("axios");
      let data = JSON.stringify({
        uid: updatedUserData.uid,
        email: updatedUserData.email
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://us-central1-el-paso-5508e.cloudfunctions.net/apiV2/updateUserEmail/a763cd056f1b2405788443b7197e0708",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Registered User
    updateRegisteredUserInformation({ state }, userData) {
      return new Promise((resolve, reject) => {
        fb.registeredUsersCollection
          .doc(userData.id)
          .update({
            name: userData.name,
            userRole: userData.userRole,
            email: userData.email,
          })
          .then(() => {
            resolve("User Information updated successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    // Invited User
    updateInvitedUserInformation({ state }, userData) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection
          .doc(userData.id)
          .update({
            name: userData.name,
            userRole: userData.userRole,
            email: userData.email,
          })
          .then(() => {
            resolve("User Information updated successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    // ------- Delete Registered OR Invited Users

    // Registered
    deleteRegisteredUser({ dispatch, getters }, user) {
      return new Promise((resolve, reject) => {
        // On deleting the user, the user should be removed from existing groups
        //dispatch('removeDeletedUserFromGroup', user)
        fb.registeredUsersCollection
          .doc(user.id)
          .delete()
          .then(() => {
            resolve("User deleted successfully");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    // Invited
    deleteInvitedUser({ state }, userId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection
          .doc(userId)
          .delete()
          .then(() => {
            resolve("User deleted successfully");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    // Invite User
    inviteNewUser({ dispatch }, user) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection
          .add({
            name: user.name,
            email: user.email,
            userRole: user.userRole,
            fdn: user.fdn,
            onCall: false,
            createdOn: new Date(),
          })
          .then((response) => {
            user["id"] = response.id;
            dispatch("sendEmail", user);
            resolve("Invitation sent successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    getInvitedUserInformation({ commit }, uId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection
          .doc(uId)
          .get()
          .then((userData) => {
            commit("setInvitedUserProfile", userData.data());
            resolve("Fetched User Information");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    updateUsersOnUpdateGroup({ getters }, group) {
      let group1 = getters.userGroups.filter((grp) => grp.id === group.id);
      let differenceUser = group1[0].users.filter(
        (x) => !group.users.includes(x)
      );
      differenceUser.forEach((user) => {
        if (group1[0].users.includes(user)) {
          fb.registeredUsersCollection.doc(user).update({
            groups: fb.firebase.firestore.FieldValue.arrayRemove(group.id),
          });
        }
      });

      let differenceUser2 = group.users.filter(
        (x) => !group1[0].users.includes(x)
      );

      differenceUser2.forEach((user) => {
        fb.registeredUsersCollection.doc(user).update({
          groups: fb.firebase.firestore.FieldValue.arrayUnion(group.id),
        });
      });
    },

    sendEmail({ getters }, user) {
      const mailObj = {
        to: user.email,
        message: {
          subject: "El Paso County Dashboard Registration",
          html:
            "Hello " +
            user.name +
            "," +
            "<br>" +
            "<br>" +
            "Please click on the link below to create you account on El Paso County dashboard." +
            "<br>" +
            "<br>" +
            "<a href='https://elpaso-dashboard.arsconnect.com/registration/'" +
            user.id +
            ">" +
            "https://elpaso-dashboard.arsconnect.com/registration/" +
            user.id +
            "</a>" +
            "<br>" +
            "<br> Thank you," +
            "<br><strong>El Paso County</strong>",
        },
      };

      var config = {
        method: "post",
        url: "https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/mail/a763cd056f1b2405788443b7197e0708",
        headers: {
          "Content-Type": "application/json",
        },
        data: mailObj,
      };

      axios(config)
        .then(function (response) {
          // resolve('Email sent successfully')
        })
        .catch(function (error) {
          //reject(error.message)
        });
    },
  },
};
